export const bannerIcon = (
  <svg viewBox="120.578 105.062 95.73 30.67" width="95.73" height="30.67">
    <path
      d="M 131.728 130.722 L 142.218 135.442 L 152.708 130.722 L 163.198 135.442 L 173.688 130.722 L 184.178 135.442 L 194.668 130.722 L 205.158 135.442 L 216.308 130.432 L 216.308 105.062 L 205.158 110.072 L 194.658 105.362 L 184.168 110.082 L 173.678 105.362 L 163.188 110.082 L 152.708 105.362 L 142.218 110.082 L 131.728 105.362 L 120.578 110.372 L 120.578 135.732 L 131.728 130.722 Z M 142.218 134.002 L 131.728 129.282 L 121.888 133.702 L 121.888 111.222 L 131.728 106.802 L 142.218 111.522 L 152.708 106.802 L 163.198 111.522 L 173.688 106.802 L 184.178 111.522 L 194.668 106.802 L 205.158 111.522 L 214.988 107.102 L 214.988 129.582 L 205.158 134.002 L 194.668 129.282 L 184.178 134.002 L 173.688 129.282 L 163.198 134.002 L 152.708 129.282 L 142.218 134.002 Z"
      transform="matrix(1, 0, 0, 1, 0, 3.552713678800501e-15)"
    />
    <text
      style={{
        fill: "#000",
        fontFamily: "Arial, sans-serif",
        fontSize: "11px",
        whiteSpace: "pre",
      }}
      x="122.401"
      y="124.185"
      transform="matrix(1, 0, 0, 1, 0, 3.552713678800501e-15)"
    >
      STATESVILLE NC
    </text>
    <ellipse
      style={{ fill: "rgb(216, 216, 216)", stroke: "rgb(0, 0, 0)" }}
      cx="195"
      cy="120"
      rx="1.2"
      ry="1.2"
      transform="matrix(1, 0, 0, 1, 0, 3.552713678800501e-15)"
    />
  </svg>
)
